import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { CircularProgress, ThemeProvider } from '@mui/material'
import { ProtectedRoute } from '@thriveglobal/thrive-web-auth-core'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'

const Sitemap = lazy(() => import('../pages/Sitemap'))
const FePlatform = lazy(() => import('../pages/FePlatform'))
const DoesNotExist = lazy(() => import('../pages/DoesNotExist'))
const PlaygroundAppleHealth = lazy(
    () => import('../pages/Playgrounds/AppleHealth/AppleHealth')
)
const ErrorBoundaries = lazy(
    () => import('../pages/Playgrounds/ErrorPlayground/ErrorPlayground')
)

enum ROUTES {
    SITEMAP = '/sitemap',
    DOES_NOT_EXIST = '/does-not-exist',
    FE_PLATFORM = '/fe-platform',
    PLAYGROUND_APPLE_HEALTH = '/playground/apple-health',
    PLAYGROUND_ERROR_BOUNDARIES = '/playground/error-boundaries'
}

const Router: React.FC = () => {
    const theme = useTheme()
    const isStaging = process.env.DEPLOYMENT_ENV === 'staging'
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Suspense fallback={<CircularProgress />}>
                    <Switch>
                        <Route
                            path={ROUTES.FE_PLATFORM}
                            component={FePlatform}
                        />
                        <ProtectedRoute
                            path={ROUTES.SITEMAP}
                            component={Sitemap}
                        />
                        <ProtectedRoute
                            path={ROUTES.DOES_NOT_EXIST}
                            component={DoesNotExist}
                        />
                        <ProtectedRoute
                            path={ROUTES.PLAYGROUND_APPLE_HEALTH}
                            component={() =>
                                isStaging ? (
                                    <PlaygroundAppleHealth />
                                ) : (
                                    <DoesNotExist />
                                )
                            }
                        />
                        <ProtectedRoute
                            path={ROUTES.PLAYGROUND_ERROR_BOUNDARIES}
                            component={() =>
                                isStaging ? (
                                    <ErrorBoundaries />
                                ) : (
                                    <DoesNotExist />
                                )
                            }
                        />
                        <ProtectedRoute default component={DoesNotExist} />
                    </Switch>
                </Suspense>
            </BrowserRouter>
        </ThemeProvider>
    )
}

export default Router
